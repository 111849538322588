<template>
  <a-modal
    title="新建"
    :width="580"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="商品名称" :labelCol="formLayout.labelCol" :wrapperCol="formLayout.wrapperCol">
              <a-select
                showSearch
                v-decorator="['barcode', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                placeholder="请输入商品名称"
                style="width: 100%"
                :filter-option="false"
                :not-found-content="goods_fetching ? undefined : null"
                allow-clear
                @search="this.goodsSearch"
                @change="handleGoodsChange"
              >
                <a-spin v-if="goods_fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in goodsList" :key="d.barcode" :value="d.barcode">
                  {{ d.brief }}（{{ d.barcode }}）
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import pick from 'lodash.pick'
import debounce from 'lodash/debounce'
import { listGoods } from '@/api/applet_goods'

const fields = ['barcode']
export default {
  name: 'CreateGroupDiscountGoodsForm',
  components: {
  },
  props: {
    model: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      formLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 15 }
        }
      },
      goodsList: [],
      goods_fetching: false,
      form: this.$form.createForm(this)
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.model !== null) {
        this.loadEditInfo(this.model)
      }
      this.handleGoodsSearch(undefined)
    })
  },
  methods: {
    loadEditInfo (data) {
      const { form } = this
      const formData = pick(data, fields)
      console.log('formData', formData)
      form.setFieldsValue(formData)
    },
    handleGoodsChange (value) {
      if (value === undefined) {
        this.handleGoodsSearch(value)
      }
    },
    handleGoodsSearch (value) {
      this.goodsList = []
      this.goods_fetching = true
      listGoods({ brief: value }).then(({ data }) => {
        const result = data.entries || []
        this.goodsList = result
      }).finally(() => {
        this.goods_fetching = false
      })
    },
    goodsSearch: debounce(function (value) {
      this.handleGoodsSearch(value)
    }, 800)
  }
}
</script>
<style>
</style>
