<template>
  <a-modal
    title="导入"
    :width="880"
    :visible="visible"
    :confirmLoading="loading"
    :maskClosable="false"
    @ok="() => { $emit('ok', fileList) }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="Excel">
              <a-button type="primary" @click="handleDownLoad">
                <a-icon type="download" />
                下载模板
              </a-button>
              <a-upload
                name="file"
                :multiple="false"
                accept=".xlsx"
                :file-list="fileList"
                :show-upload-list="true"
                :remove="handleRemove"
                :before-upload="beforeUpload">
                <a-button type="primary" style="margin-left: 10px">
                  <a-icon type="upload" />
                  导入
                </a-button>
              </a-upload>
              <span style="color: red">提醒：导入时，所有单元格格式设置成’文本‘</span>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { downloadGroupDiscountExcel } from '@/api/applet_shop_group_discount'

// 表单字段
const fields = ['id', 'name', 'code', 'wms_commodity_purchase_id']
export default {
  components: {},
  name: 'CreateDiscountImportForm',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      formLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 6 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 14 }
        }
      },
      warehouse_fetching: false,
      fetching: false,
      corporationList: [],
      form: this.$form.createForm(this),
      dataSource_wms_warehouse: [],
      fileList: []
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    handleRemove (file) {
      const index = this.fileList.indexOf(file)
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = []
    },
    beforeUpload (file) {
      this.fileList = []
      this.fileList.push(file)
      return false
    },
    handleDownLoad () {
      downloadGroupDiscountExcel().then((res) => {
        const link = document.createElement('a')
        const blob = new Blob([res], { type: 'application/octet-stream' })
        console.log(blob)
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', `商品.xlsx`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }
  }
}
</script>
