<template>
  <div>
    <a-card :bordered="false">
      <a-page-header
        style="border: 0px solid rgb(235, 237, 240); margin-bottom: 20px"
        title="关联商品"
        @back="() => $router.go(-1)"
      />
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="5" :sm="24">
              <div class="table-operator">
                <a-button type="primary" icon="plus" @click="handleCreate">添加</a-button>
                <a-button type="primary" icon="import" @click="handleImport">导入商品</a-button>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <s-table
        ref="table"
        size="small"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :data="loadData"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="action" slot-scope="text, record">
          <template>
            <a-popconfirm
              title="确定要删除么？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleDelete(record)"
            >
              <a>删除</a>
            </a-popconfirm>
          </template>
        </span>
      </s-table>
    </a-card>
    <create-group-discount-goods-form
      ref="createModal"
      v-if="visible"
      :visible.sync="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <create-discount-import-form
      ref="discountImportModal"
      v-if="import_form_visible"
      :visible.sync="import_form_visible"
      :loading="importConfirmLoading"
      @cancel="handleImportCancel"
      @ok="handleImportOk" />
  </div>
</template>

<script>
import { STable } from '@/components'
import { createGroupDiscountGoods, putGroupDiscount, groupDiscountGoodsList, deleteGroupDiscountGoods, createGroupDiscountImport } from '@/api/applet_shop_group_discount'
import CreateGroupDiscountGoodsForm from './CreateGroupDiscountGoodsForm'
import CreateDiscountImportForm from './CreateDiscountImportForm'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateGroupDiscountGoodsForm,
    CreateDiscountImportForm
  },
  data () {
    return {
      import_form_visible: false,
      importConfirmLoading: false,
      loading: false,
      visible: false,
      confirmLoading: false,
      mdl: {},
      // 查询参数
      queryParam: {
        group_discount_id: undefined
      },
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '商品名称',
          ellipsis: true,
          dataIndex: 'brief'
        },
        {
          title: '条形码',
          ellipsis: true,
          dataIndex: 'barcode'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return groupDiscountGoodsList(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return {
              entries: data
            }
          })
      }
    }
  },
  created () {
    this.queryParam.group_discount_id = this.$route.query.id
  },
  methods: {
    handleCreate () {
      this.mdl = null
      this.visible = true
      this.confirmLoading = false
      // this.$router.push({ name: 'CreateDeliveryAddress' })
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleOk () {
      const form = this.$refs.createModal.form
      form.validateFields((errors, values) => {
        if (!errors) {
          this.confirmLoading = true
          values.group_discount_id = this.queryParam.group_discount_id
          if (this.mdl && this.mdl.id) {
            putGroupDiscount(values, this.mdl.id).then((res) => {
              this.visible = false
              this.confirmLoading = false
              this.$refs.table.refresh()
            }).catch(errors => {
              values.price = (values.price / 100).toFixed(2)
            }).finally(() => {
              this.confirmLoading = false
            })
          } else {
            console.log('提交的数据----', values)
            createGroupDiscountGoods(values).then((res) => {
              this.visible = false
              this.confirmLoading = false
              this.$refs.table.refresh()
            }).catch(errors => {
              values.price = (values.price / 100).toFixed(2)
            }).finally(() => {
              this.confirmLoading = false
            })
          }
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleDelete (item) {
      console.log(item)
      deleteGroupDiscountGoods({
        barcode: item.barcode,
        group_discount_id: this.queryParam.group_discount_id
      }).then((res) => {
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      })
    },
    handleUsed (item) {
      this.loading = true
      putGroupDiscount({ is_enable: !item.is_enable }, item.id).then((res) => {
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      }).finally(() => {
        this.loading = false
      })
    },
    handleImport () {
      this.import_form_visible = true
      this.importConfirmLoading = false
    },
    handleImportCancel () {
      this.import_form_visible = false
      const form = this.$refs.discountImportModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleImportOk (fileList) {
      const form = this.$refs.discountImportModal.form
      this.importConfirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          const formData = new FormData()
          formData.append('file', fileList[0])
          formData.append('group_discount_id', this.queryParam.group_discount_id)
          createGroupDiscountImport(formData).then(res => {
            console.log(res)
            // 刷新表格
            this.$refs.table.refresh()
            if (res.code === 1000) {
              this.$refs.table.refresh()
            }
          }).finally(() => {
            this.import_form_visible = false
            this.importConfirmLoading = false
            form.resetFields()
          })
        } else {
          this.importConfirmLoading = false
        }
      })
    }
  }
}
</script>
